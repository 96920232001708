<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Grupos"
          buttonAddItemText="Novo Grupo"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="grupos"
              class="elevation-5"
              :loading="loadingTable"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :loading="loadingVisualizar[`${item.id}`]"
                    >
                      <v-icon class="actions" @click="toggleModalLateral(item)">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :loading="loadingEditar[`${item.id}`]"
                    >
                      <v-icon class="actions" @click="editar(item)">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :loading="loadingDelete[`${item.id}`]"
                    >
                      <v-icon class="actions" @click="remover(item)">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Deletar</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.icone`]="{ item }">
                <v-icon>
                  {{ item.icone }}
                </v-icon>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  color="success"
                  value="A"
                  hide-details
                  :loading="loadingStatus[`${item.id}`]"
                ></v-switch>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | dateFormatBr() }}
              </template>
              <template v-slot:[`item.padrao`]="{ item }">
                <v-chip small color="primary">
                  {{ item.padrao }}
                </v-chip>
              </template>
            </v-data-table>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <v-container fluid>
              <v-row class="ma-3" wrap>
                <!-- Nome -->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    ref="nome"
                    label="Nome do grupo"
                    placeholder="Escreva o nome do grupo"
                    required
                    outlined
                    dense
                    v-model="grupo.nome"
                    data-vv-as="nome"
                    persistent-hint
                    :hint="errors.first('nome')"
                    :error="errors.collect('nome').length ? true : false"
                    v-validate="'required|min:3'"
                    data-vv-validate-on="change"
                    id="nome"
                    name="nome"
                  ></v-text-field>
                </v-col>
                <!-- Sigla -->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    ref="sigla"
                    label="Sigla"
                    placeholder="Escreva o sigla do grupo"
                    required
                    outlined
                    dense
                    v-model="grupo.sigla"
                    data-vv-as="sigla"
                    persistent-hint
                    :hint="errors.first('sigla')"
                    :error="errors.collect('sigla').length ? true : false"
                    v-validate="'required|length:3'"
                    data-vv-validate-on="change"
                    id="sigla"
                    name="sigla"
                  ></v-text-field>
                </v-col>
                <!-- Icone -->
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    ref="icone"
                    label="Icone"
                    placeholder="Escreva o icone do grupo"
                    v-model="grupo.icone"
                    :items="SelectItensIcons"
                    outlined
                    dense
                    item-text="icone"
                    item-value="icone"
                    data-vv-as="icone"
                    persistent-hint
                    :hint="errors.first('icone')"
                    :error="errors.collect('icone').length ? true : false"
                    v-validate="'required'"
                    data-vv-validate-on="change"
                    id="icone"
                    name="icone"
                  >
                    <template v-slot:selection="data">
                      <v-icon large color="gray darken-2">
                        {{ data.item }}
                      </v-icon>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-icon large color="grey darken-2">
                          {{ data.item }}
                        </v-icon>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </Crud>
      </v-col>
    </v-row>
    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.icone`]="{ item }">
        <v-icon>{{ item.icone }}</v-icon>
      </template>
      <template v-slot:[`item.padrao`]="{ item }">
        <v-chip small color="primary">
          {{ item.padrao }}
        </v-chip>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getColor(item.status)" small dark>
          {{
            item.status == "A" || item.status == "Ativo" || item.status == ""
              ? "Ativo"
              : "Inativo"
          }}
        </v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}</span
        >
      </template>
    </ModalLateral>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";

export default {
  name: "PainelGrupos",
  inject: ["$validator"],
  components: {
    Crud,
    ModalLateral,
  },
  data: () => ({
    logotipo: logo,
    nameForm: "Novo Grupo",
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },
    grupo: {
      id: "",
      uuid: "",
      nome: "",
      sigla: "",
      icone: "",
      status: "",
      padrao: "personalizado",
    },
    SelectItensStatus: [
      { text: "Ativo", value: "A" },
      { text: "Inativo", value: "I" },
    ],
    SelectItensIcons: [
      "mdi-cash-multiple",
      "mdi-human-queue",
      "mdi-lightbulb-variant",
      "mdi-domain-plus",
      "mdi-forum-outline",
      "mdi-account-voice",
      "mdi-account-tie-voice",
      "mdi-account-tie",
      "mdi-chart-line",
      "mdi-office-building",
      "mdi-briefcase-check",
      "mdi-calendar-clock",
      "mdi-domain",
      "mdi-message-text",
      "mdi-email",
      "mdi-call-split",
      "mdi-arrow-up-bold-box-outline",
      "mdi-checkbox-marked-circle",
      "mdi-cancel",
      "mdi-minus-circle",
      "mdi-cancel",
      "mdi-arrow-left",
      "mdi-wrench",
      "mdi-cloud-upload",
      "mdi-thumb-up",
      "mdi-thumb-down",
      "mdi-account-cash",
      "mdi-access-point",
      "mdi-account-details",
      "mdi-account-supervisor",
      "mdi-all-inclusive",
      "mdi-alphabetical-variant",
      "mdi-android-messages",
      "mdi-dna",
      "mdi-attachment",
      "mdi-badge-account-horizontal",
      "mdi-bag-suitcase",
      "mdi-basketball",
      "mdi-battery-charging-high",
      "mdi-beach",
      "mdi-bell",
      "mdi-boom-gate",
      "mdi-bulletin-board",
      "mdi-cake-variant",
      "mdi-calendar-check",
      "mdi-chart-pie",
      "mdi-chair-rolling",
      "mdi-controller-classic",
    ],
    searchTextField: "",
    dialog: false,
    indexEdicao: -1,
    loadingTable: false,
    loadingVisualizar: [],
    loadingEditar: [],
    loadingDelete: [],
    loadingStatus: [],
    headers: [
      { text: "Nome", align: "start", value: "nome" },
      { text: "Sigla", value: "sigla" },
      { text: "Icone", value: "icone" },
      { text: "Padrão", value: "padrao" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    headerModalLateral: [
      { text: "Nome", align: "start", value: "nome" },
      { text: "Sigla", value: "sigla" },
      { text: "Icone", value: "icone" },
      { text: "Padrão", value: "padrao" },
      { text: "Data de criação", value: "created_at" },
      { text: "Status", value: "status" },
    ],
  }),

  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    formTitle() {
      return this.indexEdicao === -1 ? "Cadastrar grupo" : "Editar grupo";
    },
    grupos: {
      get() {
        return this.$store.getters["pcoGrandesGrupos/getGrupos"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["pcoGrandesGrupos/getLoading"];
      },
      set(value) {
        return value;
      },
    },
  },

  created() {
    this.$store.dispatch("pcoGrandesGrupos/buscarGrupos");
  },

  methods: {
    salvar() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.dialog = false;
          this.loadingTable = true;
          let url =
            this.indexEdicao === -1 ? "/grupos" : "/grupos/" + this.grupo.uuid;
          let method = this.indexEdicao === -1 ? "POST" : "PUT";
          let sucesso =
            this.indexEdicao === -1
              ? "Grupo cadastrado com sucesso."
              : "Grupo atualizado com sucesso.";
          let erro =
            this.indexEdicao === -1
              ? "Erro ao salvar grupo."
              : "Erro ao atualizar grupo.";
          let grupoItem = this.grupo;
          grupoItem._method = method;
          grupoItem.url = url;
          this.$store
            .dispatch("pcoGrandesGrupos/salvarGrupo", grupoItem)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                this.indexEdicao === -1
                  ? this.grupos.push(response)
                  : Object.assign(this.grupos[this.indexEdicao], this.grupo);
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: sucesso,
                  cor: "green",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: erro,
                cor: "red",
              });
            })
            .finally(() => {
              this.fechar();
            });
        } else {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Preencha todo formulário antes de salvar!",
            cor: "red",
          });
        }
      });
    },
    editar(item) {
      if (item.updated_at == null || item.updated_at == undefined) {
        this.loadingEditar[item.id] = true;
        this.$store
          .dispatch("pcoGrandesGrupos/buscarGrupoSelecionado", item.uuid)
          .then((response) => {
            this.indexEdicao = this.grupos.indexOf(item);
            Object.assign(this.grupos[this.indexEdicao], response.data);
            this.grupo = Object.assign({}, response.data);
            this.nameForm = "Editar grupo: " + this.grupo.nome;
            this.dialog = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao buscar todos os dados do grupo selecionado.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadingEditar[item.id] = false;
          });
      } else {
        this.nameForm = "Editar grupo: " + item.nome;
        this.indexEdicao = this.grupos.indexOf(item);
        this.grupo = Object.assign({}, item);
        this.dialog = true;
      }
    },
    fechar() {
      this.dialog = false;
      this.loadingTable = false;
      this.indexEdicao = -1;
      this.grupo = {
        nome: "",
        sigla: "",
        icone: "",
        status: "",
        padrao: "personalizado",
      };
    },
    remover(item) {
      const index = this.grupos.indexOf(item);

      this.$swal
        .fire({
          title: "Deletar",
          text: `Tem certeza que deseja deletar grupo: ${item.nome} ?`,
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#1976d2",
          confirmButtonText: "Sim, quero deleta-lo!",
          cancelButtonColor: "#555",
          cancelButtonText: "Cancelar",
        })
        .then((response) => {
          if (response.isConfirmed) {
            this.loadingTable = true;
            this.loadingDelete[item.id] = true;
            this.$store
              .dispatch("pcoGrandesGrupos/deletarGrupo", item.uuid)
              .then((response) => {
                if (response != "NAO_AUTORIZADO") {
                  this.grupos.splice(index, 1);

                  this.$swal.fire({
                    text: `Grupo deletado com sucesso!`,
                    icon: "sucess",
                    showCloseButton: true,
                    confirmButtonColor: "#1976d2",
                    confirmButtonText: "Fechar",
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  text: `Houve um erro ao deletar o Grupo! Favor verifique se existem perguntas relacionadas a ele, romova-as primeiro.`,
                  icon: "error",
                  showCloseButton: true,
                  confirmButtonColor: "#1976d2",
                  confirmButtonText: "Fechar",
                });
              })
              .finally(() => {
                this.loadingTable = false;
                this.loadingDelete[item.id] = false;
              });
          }
        });
    },
    mudarStatus(item) {
      this.loadingStatus[item.id] = true;
      this.loadingTable = true;

      this.indexEdicao = this.grupos.indexOf(item);
      this.grupo = Object.assign({}, item);
      this.grupo.status = this.grupo.status == null ? "I" : this.grupo.status;

      let grupoItem = this.grupo;
      grupoItem._method = "PUT";
      grupoItem.url = "/grupos/" + this.grupo.uuid;
      this.$store
        .dispatch("pcoGrandesGrupos/salvarGrupo", grupoItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            Object.assign(this.grupos[this.indexEdicao], this.grupo);
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Grupo atualizado com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar grupo.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingStatus[item.id] = false;
          this.loadingTable = false;
          this.indexEdicao = -1;
          this.grupo = Object.assign(
            {},
            {
              nome: "",
              sigla: "",
              icone: "",
              status: "",
              padrao: "personalizado",
            }
          );
        });
    },
    //Modal Lateral
    handleClickRow(value) {
      this.toggleModalLateral(value);
    },
    toggleModalLateral(item) {
      if (this.modalLateral.show == false) {
        if (item.updated_at == null || item.updated_at == undefined) {
          this.loadingVisualizar[item.id] = true;
          this.$store
            .dispatch("pcoGrandesGrupos/buscarGrupoSelecionado", item.uuid)
            .then((response) => {
              const index = this.grupos.indexOf(item);
              Object.assign(this.grupos[index], response.data);
              this.modalLateral.title = item.nome;
              this.modalLateral.item = item ? item : [];
              this.modalLateral.show = true;
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem:
                  "Houve um erro ao buscar todos os dados do grupo selecionado.",
                cor: "red",
              });
            })
            .finally(() => {
              this.loadingVisualizar[item.id] = false;
            });
          return;
        } else {
          this.modalLateral.title = this.modalLateral.show ? "" : item.nome;
          this.modalLateral.item = this.modalLateral.show ? [] : item;
          this.modalLateral.show = this.modalLateral.show ? false : true;
        }
      } else {
        this.modalLateral.show = false;
      }
    },
    //DATATABLE COSTUMIZATION
    getColor(status) {
      if (status == "A" || status == "Ativo") return "green";
      else return "red";
    },
  },
};
</script>

<style scoped></style>
